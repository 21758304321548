import { nextTick, reactive, ref } from 'vue'
import { cloneDeep } from 'lodash'
import { customFetch } from '@/lib/utils/Fetch'
import { transformHashKeys } from '@/lib/utils/Casing'

export function useCacheableFetchChain (tag) {
  const data = ref(null)
  const loading = ref(0)
  const cached = {}

  tag = String(tag || 'FETCH_CHAIN').toUpperCase()

  if (!tag.includes('[')) {
    tag = `[${tag}]`
  }

  let fetchable = (() => {
    const fake = Promise.resolve()
    fake.abort = () => {}
    return fake
  })()
  let loader = Promise.resolve()

  const abort = async (promise = fetchable) => {
    try {
      await promise?.abort?.()
    } catch (err) {
      logger.error(`${tag} Failed to Abort`, err)
    }
  }

  const fetchData = async (execute, transform) => {
    const previousFetch = fetchable
    const request = execute()
    const promise = request.then(response => response.json()).then(transform)
    promise.abort = request.abort
    fetchable = promise
    /* wait for proper abortion of previous fetch to prevent race conditions on assignment */
    await abort(previousFetch)

    return await promise
  }

  const errorMessage = (error, base) => {
    if (error.fetchResult) {
      const getMessage = (value) => {
        if (!value) return ''
        if (Array.isArray(value)) return value.map(getMessage).join('\n')
        if (value?.message) return value.message
        if (value?.messages) return value.messages.join('\n')
        return value
      }

      const message = getMessage(error.fetchResult.error || error.fetchResult.errors)

      return message
        ? `${base}:${message.includes('\n') ? '\n' : ' '}${message}`
        : base
    } else {
      return base
    }
  }

  const loadData = ({ execute, force, key, onError, transform = true, type, url }) => {
    ++loading.value
    let resolve
    abort(fetchable)
    const previousLoader = loader

    if (transform && typeof transform !== 'function') {
      transform = transformHashKeys
    } else {
      transform = (value) => value
    }

    // eslint-disable-next-line promise/param-names
    const promise = new Promise((r) => { resolve = r }).finally(() => { --loading.value })

    loader = promise

    if (!key) {
      return previousLoader
        .then(() => {
          if (onError) {
            onError(new Error('No Key Provided'), 'key-check', data)
          } else {
            data.value = { error: 'No Key Provided' }
          }
        })
        .finally(resolve)
    }

    if (!type) {
      return previousLoader
        .then(() => {
          if (onError) {
            onError(new Error('No Type Provided'), 'type-check', data)
          } else {
            data.value = { error: 'No Type Provided' }
          }
        })
        .finally(resolve)
    }

    if (!force && cached[key]?.[type]) {
      return previousLoader.then(() => {
        data.value = reactive(cloneDeep(cached[key][type]))
        resolve()
      })
    }

    try {
      const current = () => {
        if (loader !== promise) {
          throw new Error('aborted Request')
        }
      }

      nextTick(async () => {
        try {
          if (!execute && url) {
            if (typeof url === 'function') {
              url = await url()
            }
            execute = () => customFetch(url, { json: true })
          }

          if (typeof execute !== 'function') {
            throw new Error('Invalid Fetch Function')
          }

          const result = await fetchData(execute, transform)

          current()

          cached[key] = cached[key] || {}
          cached[key][type] = cloneDeep(result)

          await previousLoader
          current()

          data.value = reactive(result)
        } catch (error) {
          if (/aborted/.test(error.message)) return
          await previousLoader
          if (onError) {
            onError(error, 'after-fetch', data)
          } else {
            logger.error(`${tag} Error in Request`, error)
            data.value = { error: errorMessage(error, 'Invalid Request') }
          }
        } finally {
          previousLoader.then(resolve)
        }
      })
    } catch (error) {
      previousLoader
        .then(() => {
          if (/aborted/.test(error.message)) return

          if (onError) {
            onError(error, 'building-fetch', data)
          } else {
            logger.error(`${tag} Error Creating Request`, error)
            data.value = { error: errorMessage(error, 'Failed to Send Request') }
          }
        })
        .finally(resolve)
    }

    return promise
  }

  const wait = () => Promise.resolve(loader).then(() => {
    logger.debug(tag, 'wait', loading.value)
    return loading.value && wait()
  })

  const withErrorChain = async (action, onError) => {
    ++loading.value
    let resolve
    const previousLoader = loader

    // eslint-disable-next-line promise/param-names
    const promise = new Promise((r) => { resolve = r }).finally(() => { --loading.value })

    loader = promise

    try {
      const current = () => {
        if (loader !== promise) {
          throw new Error('aborted action')
        }
      }

      if (typeof action !== 'function') {
        throw new Error('Invalid Action')
      }

      await action(current)
    } catch (error) {
      if (/aborted/.test(error.message)) return

      await previousLoader

      if (onError) {
        onError(error, 'action-check', data)
      } else {
        data.value = { error: errorMessage(error, 'Invalid Action') }
      }
    } finally {
      resolve()
    }
  }

  return {
    data,
    errorMessage,
    fetcher: customFetch,
    loadData,
    loading,
    wait,
    withErrorChain,
  }
}
