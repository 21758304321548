import { useCacheableFetchChain } from '@/composables/useCacheableFetchChain'
import { getReferrer } from '@/lib/utils/Location'

export function usePdfPackageData () {
  const {
    data: buildData,
    errorMessage,
    loadData: fetchData,
    loading,
  } = useCacheableFetchChain('PDF')

  const onError = (error, checkpoint) => {
    switch (checkpoint) {
      case 'key-check':
        buildData.value = {
          error: 'No PDF collection type provided, valid types are PDF Package or Casefile',
        }
        break
      case 'type-check':
        buildData.value = {
          error: 'No patient provided',
        }
        break
      case 'after-fetch':
        logger.error('[PDF] Error in PDF Builder Request', error)
        buildData.value = {
          error: errorMessage(error, 'Invalid PDF Builder Request'),
        }
        break
      case 'building-fetch':
        logger.error('[PDF] Error creating PDF Builder Request', error)
        buildData.value = {
          error: errorMessage(error, 'Failed to create PDF Builder Request'),
        }
        break
      default:
        logger.error('[PDF] Error in PDF Builder Request', error)
        buildData.value = {
          error: errorMessage(error, 'Unexpected Error'),
        }
    }
  }

  const loadData = async ({ type, patientId }) =>
    fetchData({
      key: patientId,
      onError,
      type,
      url: () => {
        const referrer = getReferrer()
        const url = new URL(`/api/emr/patients/${patientId}/casefile/new`, window.location)
        url.searchParams.append('type', type)
        url.searchParams.append('packages', type === 'package')
        url.searchParams.append('referrer', referrer)
        return url
      },
    })

  return {
    buildData,
    getReferrer,
    loadData,
    loading,
  }
}
