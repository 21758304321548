<script setup>
import { computed } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  title: { type: String, default: '' },
  tagline: { type: String, default: '' },
  content: { type: String, default: '' },
  modelValue: { type: Boolean, default: false },
  openButtonLabel: { type: String, default: '' },
  closeButtonLabel: { type: String, default: 'Close' },
  customOverflow: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'closed'])
const isOpen = computed({
  get: () => props.modelValue,
  set: (v) => {
    emit('update:modelValue', v)
  },
})

const openModal = () => {
  logger.debug('firing openModal')
  isOpen.value = true
}

const closeModal = () => {
  logger.debug('firing closeModal')
  emit('closed')
  isOpen.value = false
}

const controls = { openModal, closeModal }
defineExpose(controls)
</script>

<template>
  <div>
    <slot
      name="openButton"
      v-bind="controls"
    >
      <button
        v-if="openButtonLabel"
        type="button"
        class="tw-rounded-md tw-bg-black tw-bg-opacity-20 tw-px-4 tw-py-2
          tw-text-sm tw-font-medium tw-text-white
          hover:tw-bg-opacity-30
          focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75"
        @click="openModal"
      >
        {{ openButtonLabel }}
      </button>
    </slot>
  </div>

  <TransitionRoot
    as="template"
    :show="isOpen"
  >
    <Dialog
      as="div"
      :open="isOpen"
      class="tw-relative tw-z-50"
    >
      <TransitionChild
        as="template"
        enter="tw-ease-out tw-duration-300"
        enter-from="tw-opacity-0"
        enter-to="tw-opacity-100"
        leave="tw-ease-in tw-duration-200"
        leave-from="tw-opacity-100"
        leave-to="tw-opacity-0"
      >
        <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
      </TransitionChild>

      <div class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
        <div class="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
          <TransitionChild
            as="template"
            enter="tw-ease-out tw-duration-300"
            enter-from="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            enter-to="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leave-from="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leave-to="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          >
            <DialogPanel
              class="
                tw-w-full
                tw-max-w-[90vw] md:tw-max-w-[85vw] 2xl:tw-max-w-8xl
                tw-px-4
              "
            >
              <div
                class="
                  tw-relative tw-rounded-lg tw-bg-white
                  tw-transform tw-transition-all
                  tw-h-screen tw-w-full
                  tw-p-8 tw-pt-4
                  tw-min-h-[85vh] tw-max-h-[85vh]
                  tw-overflow-x-visible tw-overflow-y-visible
                  tw-text-left tw-shadow-xl
                  tw-flex tw-flex-col tw-items-start tw-justify-start
                "
              >
                <div class="tw-flex tw-flex-col tw-flex-grow tw-h-px tw-w-full">
                  <DialogTitle
                    as="div"
                    class="tw-flex tw-justify-between tw-mb-4"
                  >
                    <div>
                      <div class="tw-flex tw-items-center tw-gap-4">
                        <slot name="icon" />
                        <h3
                          v-if="title"
                          class="tw-font-bold tw-text-xl"
                        >
                          {{ title }}
                        </h3>
                        <slot name="title" />
                      </div>

                      <div
                        v-if="tagline"
                        class="tw-font-bold tw-text-xs"
                      >
                        {{ tagline }}
                      </div>
                    </div>

                    <span
                      class="mdi mdi-close hover:tw-cursor-pointer"
                      @click="closeModal"
                    />
                  </DialogTitle>

                  <div class="tw-mt-2 tw-flex-1 tw-h-px">
                    <div
                      class="tw-w-full tw-h-full"
                      :class="{ 'tw-overflow-y-auto': !customOverflow }"
                    >
                      <slot>
                        <p class="tw-text-sm tw-text-gray-500">
                          {{ content }}
                        </p>
                      </slot>
                    </div>
                  </div>
                </div>

                <div
                  v-if="$slots.controls || closeButtonLabel"
                  class="tw-mt-5 sm:tw-mt-6"
                >
                  <slot
                    name="controls"
                    v-bind="controls"
                  >
                    <button
                      v-if="closeButtonLabel"
                      type="button"
                      class="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-indigo-600
                        tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm
                        hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
                        focus-visible:tw-outline-indigo-600"
                      @click="closeModal"
                    >
                      {{ closeButtonLabel }}
                    </button>
                  </slot>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
