<script setup>
import { computed, ref } from 'vue'
import { useVModel } from '@/composables/useVModel'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { startCase, kebabCase } from 'lodash'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  name: { type: String, required: true },
  label: { type: [String, null], default: null },
  id: { type: [String, null], default: null },
  modelValue: { type: [Object, null], default: null },
  validation: { type: [Object, null], default: null },
  options: { type: Array, default: () => [] },
  placeholder: { type: String, default: 'Select an option' },
  required: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'blur'])
const current = useVModel(props, emit)

const selectedName = computed(() => {
  return current.value?.name || props.placeholder
})

const resolvedId = computed(() => {
  return props.id || kebabCase(props.name) // eg turn rails user[name] to id user-name
})

const resolvedLabel = computed(() => {
  return props.label === undefined ? startCase(props.name) : props.label // eg first_name becomes "First Name"
})

const input = ref(null)

const validityClasses = computed(() => props.validation
  ? 'tw-text-red-900 tw-ring-2 tw-ring-red-300 focus:tw-ring-red-500 placeholder:tw-text-red-300'
  : 'tw-text-gray-900 tw-ring-gray-300 focus:tw-ring-indigo-600',
)

// extra div to prevent "runtime directive used on component non-element root node" eg when
// calling v-focus custom directive from parent.  not sure i really want this but this is why
// is extra div - it's also consistent with native div wrappers around text box/area components
// so maybe a good idea to wrap headless components in an extra div
</script>

<template>
  <div>
    <Listbox
      v-model="current"
      :name="name"
      as="div"
    >
      <ListboxLabel
        v-if="resolvedLabel"
        class="tw-block tw-text-sm tw-font-medium twx-leading-6 tw-text-gray-900"
      >
        {{ resolvedLabel }}{{ required ? ' *' : '' }}
      </ListboxLabel>

      <div class="tw-relative tw-mt-1">
        <!-- blur also fires when drop down opened tried capture above div and stops/prevents -->
        <ListboxButton
          :id="resolvedId"
          ref="input"
          as="div"
          class="tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10
            tw-text-left tw-shadow-sm tw-ring-1 tw-ring-inset
            focus:tw-outline-none focus:tw-ring-2
            sm:tw-text-sm sm:tw-leading-6"
          :class="validityClasses"
          :aria-required="!!required"
          @blur.self="(ev) => emit('blur', ev)"
        >
          <span class="tw-block tw-truncate">{{ selectedName }}</span>
          <span class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
            <ChevronUpDownIcon
              class="tw-h-5 tw-w-5 tw-text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="tw-transition tw-ease-in tw-duration-100"
          leave-from-class="tw-opacity-100"
          leave-to-class="tw-opacity-0"
        >
          <ListboxOptions
            class="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md
              tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5
              focus:tw-outline-none sm:tw-text-sm tw-min-w-fit"
            @blur.prevent.stop
          >
            <ListboxOption
              v-if="placeholder && !required"
              v-slot="{ active, selected }"
              as="template"
              :value="null"
            >
              <li
                :class="[
                  active
                    ? 'tw-bg-indigo-600 tw-text-white'
                    : 'tw-text-gray-900',
                  'tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9'
                ]"
              >
                <span
                  :class="[selected ? 'tw-font-semibold' : 'tw-font-normal', 'tw-block tw-truncate']"
                >
                  {{ placeholder }}
                </span>

                <span
                  v-if="selected"
                  :class="[active ? 'tw-text-white' : 'tw-text-indigo-600',
                           'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4']"
                >
                  <CheckIcon
                    class="tw-h-5 tw-w-5"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>

            <ListboxOption
              v-for="option in options"
              :key="option.id || 'placeholder'"
              v-slot="{ active, selected }"
              as="template"
              :value="option"
            >
              <li
                :class="[active ? 'tw-bg-indigo-600 tw-text-white' : 'tw-text-gray-900',
                         'tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9']"
              >
                <span
                  :class="[selected ? 'tw-font-semibold' : 'tw-font-normal', 'tw-block tw-truncate']"
                >{{ option.name }}</span>

                <span
                  v-if="selected"
                  :class="[active ? 'tw-text-white' : 'tw-text-indigo-600',
                           'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4']"
                >
                  <CheckIcon
                    class="tw-h-5 tw-w-5"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <select
      v-if="required && !current"
      :name="`${name}[id]`"
      required="required"
      class="
        tw-block
        tw-h-px
        tw-w-full
        tw-border-none
        tw-bg-transparent
        tw-ring-none
        tw-overflow-hidden
        tw-m-0 tw--mt-px tw-p-0 tw-opacity-0
        tw-pointer-events-none
        tw-absolute
      "
      tabindex="-1"
      @change="current = options.find(option => option.id === $event.target.value)"
    >
      <option value="">
        Select an option
      </option>
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>
